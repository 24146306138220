import { capitalCase } from "change-case";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Card,
  Stack,
  Link,
  Alert,
  Tooltip,
  Container,
  Typography,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Table,
} from "@mui/material";
// routes
import { PATH_AUTH } from "../../routes/paths";
// hooks
import useAuth from "../../hooks/useAuth";
import useResponsive from "../../hooks/useResponsive";
// components
import Page from "../../components/Page";
import Logo from "../../components/Logo";
import Image from "../../components/Image";
// sections
import { LoginForm } from "../../sections/auth/login";
import { TableHeadCustom } from "src/components/table";
import Scrollbar from "src/components/Scrollbar";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  height: "75vh",

  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),

  marginTop: "10rem",
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

const TABLE_HEAD = [
  {
    id: 2,
    label: "Область обучения (специализация)",
    width: "auto",
  },
  {
    id: 3,
    label: "Название зарубежной страны",
    width: "auto",
  },
  {
    id: 4,
    label: "Название образовательных специальностей высших учебных заведений",
    width: "auto",
  },
  {
    id: 5,
    label: "Язык обучения",
    width: "auto",
  },
  {
    id: 6,
    label: "Срок обучения (2+2, 3+1, 1+1 и др.)",
    width: "auto",
  },
];

const TABLE_HEAD2 = [
  {
    id: 2,
    label: "Область обучения (специализация)",
    width: "auto",
  },
  {
    id: 3,
    label: "Название зарубежной страны",
    width: "auto",
  },
  {
    id: 4,
    label: "Название образовательных специальностей высших учебных заведений",
    width: "auto",
  },
  {
    id: 5,
    label: "Язык обучения",
    width: "auto",
  },
  {
    id: 6,
    label: "Срок обучения (2+2, 3+1, 1+1 и др.)",
    width: "auto",
  },
];

const tableData1 = [
  {
    type: "Бакалавр",
    yunalish: "Лечебное дело",
    state: "Российская Федерация",
    institute: "РНИМУ имени Н.И. Пирогова",
    lang: "рус",
    term: "3+2+1",
  },
  {
    type: "Бакалавр",
    yunalish: "Педиатрия",
    state: "Российская Федерация",
    institute:
      "Санкт-Петербургский государственный педиатрический медицинский университет",
    lang: "рус",
    term: "3+3",
  },
  {
    type: "Бакалавр",
    yunalish: "Лечебное дело",
    state: "Российская Федерация",
    institute: "Волгоградский государственный медицинский университет",
    lang: "рус",
    term: "3+3",
  },

  {
    type: "Бакалавр",
    yunalish: "Стоматология",
    state: "Российская Федерация",
    institute: "Волгоградский государственный медицинский университет",
    lang: "рус",
    term: "3+2",
  },

  {
    type: "Бакалавр",
    yunalish: "Педиатрия",
    state: "Российская Федерация",
    institute: "Волгоградский государственный медицинский университет",
    lang: "рус",
    term: "3+3",
  },

  {
    type: "Бакалавр",
    yunalish: "Лечебное дело",
    state: "Российская Федерация",
    institute: "Балтийский федеральный университет (РФ) ",
    lang: "рус",
    term: "3+3",
  },
  {
    type: "Бакалавр",
    yunalish: "Лечебное дело",
    state: "Российская Федерация",
    institute: "Российский университет дружбы народов имени Патриса Лумумбы",
    lang: "рус",
    term: "3+3",
  },
  {
    type: "Бакалавр",
    yunalish: "Стоматология",
    state: "Российская Федерация",
    institute: "Российский университет дружбы народов имени Патриса Лумумбы",
    lang: "рус",
    term: "3+2",
  },
  {
    type: "Бакалавр",
    yunalish: "Фармация",
    state: "Индия",
    institute: "Университет GD Goenka",
    lang: "англ",
    term: "2,5+2,5",
  },
  {
    type: "Бакалавр",
    yunalish: "Высшее сестринское дело",
    state: "Италия",
    institute: "Университет Luigi Vanvitelli",
    lang: "англ",
    term: "2+1+1",
  },
];

const tableData2 = [
  {
    yunalish:
      "Инструментальные и функциональные методы диагностики (Бактериология и вирусология)",
    institute: "Национальный университет Казахстана имени Аль-Фараби",
    state: "Республика Казахстан",
    lang: "рус",
    term: "1+1",
  },
  {
    yunalish: "Менеджмент (Менеджмент в здравоохранении)",
    institute: "Высшая школа бизнеса – Национальная – Университет Луиса",
    state: "Польша",
    lang: "англ/рус",
    term: "1+1",
  },
];
// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuth();

  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");

  return (
    <Page title="Авторизация">
      <RootStyle>
        <HeaderStyle>
          <Stack flexDirection="row" alignItems="center" gap={1}>
            <Logo />
            <Box>
              <Typography variant="h5">
                Бухарский государственный медицинский институт
              </Typography>
              <Typography variant="caption">
                Система приёма документов
              </Typography>
            </Box>
          </Stack>

          {smUp && (
            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
              Нет аккаунта? {""}
              <Link
                variant="subtitle2"
                component={RouterLink}
                to={PATH_AUTH.register}
              >
                Зарегистрируйтесь
              </Link>
            </Typography>
          )}
        </HeaderStyle>

        {mdUp && (
          <SectionStyle sx={{ textAlign: "center" }}>
            <Box
              px={5}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography sx={{ my: 2 }} fontWeight={900} maxWidth="550px">
                О совместных образовательных программах, организованных в
                Бухарском государственном медицинском институте в 2024/2025
                учебном году совместно с зарубежными высшими учебными
                заведениями по следующим направлениям и специальностям
              </Typography>
              <Typography
                sx={{ my: 2 }}
                fontWeight={900}
                maxWidth="550px"
                fontSize={20}
              >
                ИНФОРМАЦИЯ
              </Typography>
            </Box>
            <Scrollbar>
              <TableContainer sx={{ position: "relative" }}>
                <Table size={"small"}>
                  <TableHeadCustom headLabel={TABLE_HEAD} />

                  <TableBody>
                    {tableData1.map((row, idx) => (
                      <TableRow key={idx}>
                        <TableCell align="left">{row?.type}</TableCell>
                        <TableCell align="left">{row?.yunalish}</TableCell>
                        <TableCell align="left">{row?.state}</TableCell>
                        <TableCell align="left">{row?.institute}</TableCell>
                        <TableCell align="left">{row?.lang}</TableCell>
                        <TableCell align="left">{row?.term}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Typography sx={{ my: 2 }} fontWeight={900} fontSize={18}>
                Магистратура
              </Typography>
              <TableContainer sx={{ position: "relative" }}>
                <Table size={"small"}>
                  <TableHeadCustom headLabel={TABLE_HEAD2} />

                  <TableBody>
                    {tableData2.map((row, idx) => (
                      <TableRow key={idx}>
                        <TableCell align="left">{row?.yunalish}</TableCell>

                        <TableCell align="left">{row?.state}</TableCell>
                        <TableCell align="left">{row?.institute}</TableCell>
                        <TableCell align="left">{row?.lang}</TableCell>
                        <TableCell align="left">{row?.term}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </SectionStyle>
        )}

        <Container sx={{ width: "auto", mt: 3 }}>
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Alert severity="info" sx={{ mb: 3 }}>
                  Обратная связь:
                  <Typography variant="caption" component="div" sx={{ mt: 1 }}>
                    +998 90 711-54-70
                  </Typography>
                  <Typography variant="caption" component="div" sx={{ mt: 1 }}>
                    +998 91 649-54-45
                  </Typography>
                </Alert>
                <Typography variant="h4" gutterBottom>
                  Авторизация
                </Typography>
                {/* <Typography sx={{ color: "text.secondary" }}>
                  Enter your details below.
                </Typography> */}
              </Box>

              {/* <Tooltip title={capitalCase(method)} placement="right">
                <>
                  <Image
                    disabledEffect
                    src={`https://minimal-assets-api.vercel.app/assets/icons/auth/ic_${method}.png`}
                    sx={{ width: 32, height: 32 }}
                  />
                </>
              </Tooltip> */}
            </Stack>

            {/* <Alert severity="info" sx={{ mb: 3 }}>
              Введите логин и пароль для входа в личный кабинет
            </Alert> */}

            <LoginForm />

            {!smUp && (
              <Typography variant="body2" sx={{ mt: { md: -2 } }}>
                Нет аккаунта? {""}
                <Link
                  variant="subtitle2"
                  component={RouterLink}
                  to={PATH_AUTH.register}
                >
                  Зарегистрируйтесь
                </Link>
              </Typography>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
