import { createSlice } from "@reduxjs/toolkit";
import sum from "lodash/sum";
import uniqBy from "lodash/uniqBy";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  userData: null,
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // LOGIN
    loginSuccess(state, action) {
      state.isLoading = false;
      state.userData = action.payload;
    },

    registerSuccess(state, action) {
      state.isLoading = false;
      state.userData = action.payload;
    },

    clearUserData(state, action) {
      state = initialState;
    },
  },
});

// Reducer
export default slice.reducer;

export const { clearUserData } = slice.actions;

// ----------------------------------------------------------------------

export function login(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/login/", data);
      localStorage.setItem("accessToken", response.data.data.access);
      localStorage.setItem("refreshToke", response.data.data.refresh);
      dispatch(slice.actions.loginSuccess(response.data.user));
    } catch (error) {
      dispatch(slice.actions.hasError("Ошибка при авторизации"));
    }
  };
}

// ----------------------------------------------------------------------

export function register() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/qabul/register-student");
      localStorage.setItem("accessToken", response.data.data.access);
      localStorage.setItem("refreshToke", response.data.data.refresh);
      dispatch(slice.actions.registerSuccess(response.data.user));
    } catch (error) {
      dispatch(slice.actions.hasError("Ошибка при регистрации"));
    }
  };
}
