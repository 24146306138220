import * as React from "react";
import Drawer from "@mui/material/Drawer";
import {
  Alert,
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FormProvider, RHFUploadMultiFile } from "./hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import getFileSize from "src/utils/getFileSize";
import { getFileFormat, get, getFileThumb } from "src/utils/getFileFormat";
import getFileName from "src/utils/getFileName";
import { LoadingButton } from "@mui/lab";
import { uploadDocument } from "src/redux/slices/document";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import Image from "../components/Image";
import moment from "moment";

export default function DocumentDrawer({
  open,
  onClose,
  allowedFileTypes = [],
  errorMessage = "",
  docType,
  docFieldName,
}) {
  const [file, setFile] = React.useState(null);
  const [error, setError] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const fileInputRef = React.useRef(null);

  const { isLoading } = useSelector((state) => state.document);
  const { data } = useSelector((state) => state.userData);
  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (allowedFileTypes.includes(file.type)) {
        setFile(e.target.files[0]);
        setError(false);
        setMessage("");
      } else {
        setFile(null);
        setError(true);
        setMessage(errorMessage);
        fileInputRef.current.value = "";
      }
    }
  };

  const onSave = () => {
    const formData = new FormData();

    formData.append(docFieldName, file);

    dispatch(
      uploadDocument(formData, docFieldName, () => {
        onClose();
        enqueueSnackbar("Документ сохранён!");
      })
    );
  };

  const title = {
    application: "Заявление",
    passport_photo: "Паспорт",
    patent: "Диплом/Атестат",
    benefit: "Сертификаты",
  };

  return (
    <Drawer
      BackdropProps={{ style: { background: "transparent" } }}
      open={open}
      onClose={onClose}
      anchor="right"
      PaperProps={{
        sx: { width: "320px", background: "rgb(244, 246, 248)" },
      }}
      sx={{ position: "relative" }}
    >
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ background: "#fff", padding: 2 }}
      >
        <Typography variant="h6">{title[docFieldName]}</Typography>
        {data?.[docFieldName]?.status ? (
          <CheckCircleOutlineIcon color="success" />
        ) : (
          <QueryBuilderIcon sx={{ color: "orange" }} />
        )}
      </Stack>
      {docFieldName == "patent" && (
        <Alert severity="info">
          Документ об образовании (или выписка из диплома – для окончивших
          высшие учебные заведения Республики Узбекистан на грантовой основе) и
          приложение к нему.
          <Typography color="green" component="div" variant="caption">
            Документы должны быть в формате PDF!
          </Typography>
          <Typography color="green" component="div" variant="caption">
            Максимальный размер загружаемого файла 2 МБ!
          </Typography>
        </Alert>
      )}
      {/* {docFieldName == "document_086" && (
        <Alert severity="info">
          086-У-шаклидаги тиббий маълумотнома
          <Typography color="green" component="div" variant="caption">
            Документы должны быть в формате PDF!
          </Typography>
           <Typography color="green" component="div" variant="caption">
           Максимальный размер загружаемого файла 2 МБ!
          </Typography>
        </Alert>
      )}{" "} */}
      {docFieldName == "application" && (
        <Alert severity="info">
          <Link href="/zayavleniye_sop_2024.docx">Образец</Link> заявления на имя ректора
          БухГМИ с указанием специальности
          <Typography color="green" component="div" variant="caption">
            Документы должны быть в формате PDF!
          </Typography>
          <Typography color="green" component="div" variant="caption">
            Максимальный размер загружаемого файла 2 МБ!
          </Typography>
        </Alert>
      )}{" "}
      {docFieldName == "passport_photo" && (
        <Alert severity="info">
          Паспорт (страницы с фотографией и прописка с печатью)
          <Typography color="green" component="div" variant="caption">
            Документы должны быть в формате PDF!
          </Typography>
          <Typography color="green" component="div" variant="caption">
            Максимальный размер загружаемого файла 2 МБ!
          </Typography>
        </Alert>
      )}
      {docFieldName == "benefit" && (
        <Alert severity="info">
          Документ, предоставляющий льготы при приеме на обучение в соответствии
          с законодательными документами.
          <Typography color="green" component="div" variant="caption">
            Документы должны быть в формате PDF!
          </Typography>
          <Typography color="green" component="div" variant="caption">
            Максимальный размер загружаемого файла 2 МБ!
          </Typography>
        </Alert>
      )}
      {(file || data?.[docFieldName]) && (
        <Stack sx={{ padding: 2 }} gap="20px">
          {getFileThumb(
            file || {
              path: data?.[docFieldName].path,
              type: data?.[docFieldName].type,
              name: data?.[docFieldName].name,
            }
          )}

          {/* <Box
            borderRadius={2}
            component="img"
            src={file ? URL.createObjectURL(file) : ""}
          /> */}
          {file && (
            <Typography variant="subtitle1">{getFileName(file)}</Typography>
          )}
          {data?.[docFieldName] && (
            <Typography variant="subtitle1">
              {data?.[docFieldName].name}
            </Typography>
          )}
          <Divider />
        </Stack>
      )}
      <Box
        component="label"
        ref={fileInputRef}
        sx={{
          margin: "4px 4px 24px",
          width: "auto",
          height: "auto",
          flexShrink: "0",
          display: "flex",
          borderRadius: "12px",
          cursor: "pointer",
          webkitBoxAlign: "center",
          alignItems: "center",
          color: "rgb(145, 158, 171)",
          webkitBoxPack: "center",
          justifyContent: "center",
          backgroundColor: " rgba(145, 158, 171, 0.08)",
          border: "1px dashed rgba(145, 158, 171, 0.16)",
          paddingTop: "20px",
          paddingBottom: "20px",
          "&:hover": {
            opacity: 0.72,
          },
        }}
      >
        <input
          ref={fileInputRef}
          type="file"
          hidden
          onChange={handleFileInputChange}
          accept={allowedFileTypes}
        />
        <Stack
          sx={{
            flexDirection: "column",
            gap: "4px",
            webkitBoxAlign: "center",
            alignItems: "center",
            color: "rgb(145, 158, 171);",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            aria-hidden="true"
            role="img"
            className="component-iconify MuiBox-root css-1ngw63v iconify iconify--eva"
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
          >
            <g id="iconifyReact961">
              <g id="iconifyReact962">
                <g id="iconifyReact963" fill="currentColor">
                  <path d="M21.9 12c0-.11-.06-.22-.09-.33a4.17 4.17 0 0 0-.18-.57c-.05-.12-.12-.24-.18-.37s-.15-.3-.24-.44S21 10.08 21 10s-.2-.25-.31-.37s-.21-.2-.32-.3L20 9l-.36-.24a3.68 3.68 0 0 0-.44-.23l-.39-.18a4.13 4.13 0 0 0-.5-.15a3 3 0 0 0-.41-.09L17.67 8A6 6 0 0 0 6.33 8l-.18.05a3 3 0 0 0-.41.09a4.13 4.13 0 0 0-.5.15l-.39.18a3.68 3.68 0 0 0-.44.23l-.36.3l-.37.31c-.11.1-.22.19-.32.3s-.21.25-.31.37s-.18.23-.26.36s-.16.29-.24.44s-.13.25-.18.37a4.17 4.17 0 0 0-.18.57c0 .11-.07.22-.09.33A5.23 5.23 0 0 0 2 13a5.5 5.5 0 0 0 .09.91c0 .1.05.19.07.29a5.58 5.58 0 0 0 .18.58l.12.29a5 5 0 0 0 .3.56l.14.22a.56.56 0 0 0 .05.08L3 16a5 5 0 0 0 4 2h3v-1.37a2 2 0 0 1-1 .27a2.05 2.05 0 0 1-1.44-.61a2 2 0 0 1 .05-2.83l3-2.9A2 2 0 0 1 12 10a2 2 0 0 1 1.41.59l3 3a2 2 0 0 1 0 2.82A2 2 0 0 1 15 17a1.92 1.92 0 0 1-1-.27V18h3a5 5 0 0 0 4-2l.05-.05a.56.56 0 0 0 .05-.08l.14-.22a5 5 0 0 0 .3-.56l.12-.29a5.58 5.58 0 0 0 .18-.58c0-.1.05-.19.07-.29A5.5 5.5 0 0 0 22 13a5.23 5.23 0 0 0-.1-1Z"></path>
                  <path d="M12.71 11.29a1 1 0 0 0-1.4 0l-3 2.9a1 1 0 1 0 1.38 1.44L11 14.36V20a1 1 0 0 0 2 0v-5.59l1.29 1.3a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42Z"></path>
                </g>
              </g>
            </g>
          </svg>
          <Typography variant="body2">Загрузить файл</Typography>
        </Stack>
      </Box>
      {error && <Alert severity="error">{message}</Alert>}
      {/* <Stack sx={{ padding: 2 }} gap="20px">
        <FormProvider methods={methods} onSubmit={handleSubmit(() => {})}>
          <RHFUploadMultiFile
            name="images"
            showPreview
            accept="image/*"
            maxSize={3145728}
            onDrop={handleDrop}
            onRemove={handleRemove}
            onRemoveAll={handleRemoveAll}
          />
        </FormProvider>
      </Stack> */}
      {(file || data?.[docFieldName]) && (
        <Stack sx={{ padding: 2 }} gap="20px">
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            Свойства файла
            <IconButton aria-label="delete">
              <KeyboardArrowDownIcon />
            </IconButton>
          </Stack>
          <Stack flexDirection="row" alignItems="center" fontSize="0.75rem">
            <Box
              component="span"
              width={80}
              color="rgb(99, 115, 129)"
              marginRight={2}
            >
              Размер
            </Box>
            {file && getFileSize(file)}
            {data?.[docFieldName] && getFileSize(data?.[docFieldName].size)}
          </Stack>
          <Stack flexDirection="row" alignItems="center" fontSize="0.75rem">
            <Box
              component="span"
              width={80}
              color="rgb(99, 115, 129)"
              marginRight={2}
            >
              Дата
            </Box>
            {file && moment(file.lastModifiedDate).format("DD.MM.YYYY hh:mm")}
            {data?.[docFieldName] &&
              moment(data?.[docFieldName].created_at).format(
                "DD.MM.YYYY hh:mm"
              )}
          </Stack>
          <Stack flexDirection="row" alignItems="center" fontSize="0.75rem">
            <Box
              component="span"
              width={80}
              color="rgb(99, 115, 129)"
              marginRight={2}
            >
              Тип
            </Box>
            {file && file.name.split(".").pop()}
            {data?.[docFieldName] && data?.[docFieldName].type}
          </Stack>
        </Stack>
      )}
      <Stack
        gap={1}
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        position="fixed"
        bottom={0}
        sx={{ background: "#fff" }}
        padding={1}
        width={320}
      >
        <LoadingButton
          variant="contained"
          fullWidth
          onClick={onSave}
          loading={isLoading}
        >
          Сохранить
        </LoadingButton>
        <Button variant="contained" fullWidth onClick={onClose}>
          Закрыть
        </Button>
      </Stack>
    </Drawer>
  );
}
