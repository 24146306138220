import * as React from "react";
import Drawer from "@mui/material/Drawer";
import {
  Alert,
  Box,
  Button,
  Divider,
  IconButton,
  ListItemText,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Card,
  Badge,
  Chip,
  MenuItem,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FormProvider, RHFUploadMultiFile } from "./hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import getFileSize from "src/utils/getFileSize";
import { getFileFormat, get, getFileThumb } from "src/utils/getFileFormat";
import getFileName from "src/utils/getFileName";
import { TableMoreMenu } from "./table";
import Iconify from "./Iconify";
import ConfirmDocumentDialog from "./ConfirmDocumentDialog";
import DocumentTableRow from "./DocumentTableRow";
import { useSelector } from "react-redux";

export default function UserInfoDrawer({
  open,
  onClose,
  allowedFileTypes = [],
  errorMessage = "",
}) {
  const [file, setFile] = React.useState(null);
  const [error, setError] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const fileInputRef = React.useRef(null);

  const { selected } = useSelector((state) => state.statement);

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (allowedFileTypes.includes(file.type)) {
        setFile(e.target.files[0]);
        setError(false);
        setMessage("");
      } else {
        setFile(null);
        setError(true);
        setMessage(errorMessage);
        fileInputRef.current.value = "";
      }
    }
  };

  const filesData = [
    {
      reqiredFileType: "word",
      docType: "Заявление",
      fileName: "моё_заявление",
      fileType: "PDF",
      docFieldName: "application",
      allowedFileTypes: ["application/pdf"],
    },
    // {
    //   reqiredFileType: "pdf",
    //   docType: "086-У-шаклидаги тиббий маълумотнома",
    //   fileName: "моё_заявление",
    //   fileType: "PDF",
    //   docFieldName: "document_086",
    //   allowedFileTypes: ["application/pdf"],
    // },
    {
      reqiredFileType: "pdf",
      docType: "Документ о среднем/высшем образовании",
      fileName: "моё_заявление",
      fileType: "PDF",
      docFieldName: "patent",
      allowedFileTypes: ["application/pdf"],
    },
    {
      reqiredFileType: "pdf",
      docType: "Паспорт",
      fileName: "моё_заявление",
      fileType: "PDF",
      docFieldName: "passport_photo",
      allowedFileTypes: ["application/pdf"],
    },
    {
      reqiredFileType: "pdf",
      docType: "Сертификаты",
      fileName: "моё_заявление",
      fileType: "PDF",
      docFieldName: "benefit",
      allowedFileTypes: ["application/pdf"],
    },
  ];

  return (
    <Drawer
      BackdropProps={{ style: { background: "transparent" } }}
      open={open}
      onClose={onClose}
      anchor="right"
      PaperProps={{
        sx: { width: "800px", p: 1, background: "rgb(244, 246, 248)", gap: 1 },
      }}
      sx={{ position: "relative" }}
    >
      <Stack
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ background: "#fff", padding: 2 }}
      >
        <Typography variant="h6">{`${selected?.user?.middle_name} ${selected?.user?.name} ${selected?.user?.last_name}`}</Typography>
        {selected?.status == 3 && <CheckCircleOutlineIcon color="success" />}
        {(selected?.status == 2 || selected?.status == 1) && (
          <Iconify
            icon={"eva:clock-outline"}
            sx={{
              width: 20,
              height: 20,
              color: "warning.main",
            }}
          />
        )}
      </Stack>
      <Stack mb={7}>
        <Stack sx={{ padding: 2 }} gap="20px">
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            Информация абитуриента
            <IconButton aria-label="delete">
              <KeyboardArrowDownIcon />
            </IconButton>
          </Stack>
          <Stack
            flexDirection="row"
            alignItems="center"
            fontSize="0.75rem"
            fontWeight="900"
          >
            <Box
              component="span"
              width={160}
              color="rgb(99, 115, 129)"
              marginRight={2}
            >
              Направравление
            </Box>
            {selected?.major?.name || "Танланмаган"}
          </Stack>
          <Stack flexDirection="row" alignItems="center" fontSize="0.75rem">
            <Box
              component="span"
              width={160}
              color="rgb(99, 115, 129)"
              marginRight={2}
            >
              Дата регистрации
            </Box>
            {selected?.created_data}
            {/* 30 May 2023 8:09 PM */}
          </Stack>
          <Stack flexDirection="row" alignItems="center" fontSize="0.75rem">
            <Box
              component="span"
              width={160}
              color="rgb(99, 115, 129)"
              marginRight={2}
            >
              Паспорт
            </Box>
            {selected?.passport}
            {/* 30 May 2023 8:09 PM */}
          </Stack>
          <Stack flexDirection="row" alignItems="center" fontSize="0.75rem">
            <Box
              component="span"
              width={160}
              color="rgb(99, 115, 129)"
              marginRight={2}
            >
              ПИНФЛ
            </Box>
            {selected?.jshir}
            {/* 30 May 2023 8:09 PM */}
          </Stack>
          <Stack flexDirection="row" alignItems="center" fontSize="0.75rem">
            <Box
              component="span"
              width={160}
              color="rgb(99, 115, 129)"
              marginRight={2}
            >
              Номер телефона
            </Box>
            {selected?.user?.phone}
            {/* 30 May 2023 8:09 PM */}
          </Stack>
          <Stack flexDirection="row" alignItems="center" fontSize="0.75rem">
            <Box
              component="span"
              width={160}
              color="rgb(99, 115, 129)"
              marginRight={2}
            >
              Дополнительный номер
            </Box>
            {selected?.second_phone}
            {/* 30 May 2023 8:09 PM */}
          </Stack>
          <Stack flexDirection="row" alignItems="center" fontSize="0.75rem">
            <Box
              component="span"
              width={160}
              color="rgb(99, 115, 129)"
              marginRight={2}
            >
              Электронная почта
            </Box>
            {selected?.user?.email}{" "}
            <Chip
              size="small"
              sx={{ marginLeft: 1, fontSize: "12px" }}
              label="Подтверждена"
              color="primary"
            />
            {/* {getFileFormat(file)} */}
          </Stack>
          <Stack flexDirection="row" alignItems="center" fontSize="0.75rem">
            <Box
              component="span"
              width={160}
              color="rgb(99, 115, 129)"
              marginRight={2}
            >
              Адрес
            </Box>
            {selected?.region?.name || "Страна не выбрана"}.{" "}
            {selected?.address || "Адрес не заполнен"}
            {/* {getFileFormat(file)} */}
          </Stack>
        </Stack>
        <Card sx={{ p: 2 }}>
          <Typography marginLeft={1} fontWeight={900}>
            Файлы
          </Typography>
          <Table
            size="small"
            sx={{ borderSpacing: "0px 16px", borderCollapse: "separate" }}
          >
            <TableHead>
              <TableRow>
                <TableCell>Тип документа</TableCell>
                {/* <TableCell>Hujjat nomi</TableCell> */}
                <TableCell>Тип файла</TableCell>
                <TableCell>Размер файла</TableCell>
                <TableCell>Изменено</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filesData.map((item, idx) => (
                <DocumentTableRow data={item} key={idx} user={selected} />
              ))}
              {/* <TableRow
                sx={{
                  "&:hover": {
                    boxShadow: "rgba(145, 158, 171, 0.16) 0px 20px 40px -4px",
                  },
                  cursor: "pointer",
                  backgroundColor: "rgba(255,165,0,0.2)",
                  transition:
                    "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                }}
              >
                <TableCell
                  sx={{
                    borderTopLeftRadius: "16px",
                    borderBottomLeftRadius: "16px",
                    borderLeft: "1px solid rgba(145, 158, 171, 0.16)",
                    borderTop: "1px solid rgba(145, 158, 171, 0.16)",
                    borderBottom: "1px solid rgba(145, 158, 171, 0.16)",
                  }}
                >
                  <Stack flexDirection="row" alignItems="center" gap={2}>
                    <img height={36} width={36} src="/icons/ic_word.svg" />
                    <Typography>Заявление</Typography>
                  </Stack>
                </TableCell>
                <TableCell
                  sx={{
                    borderTop: "1px solid rgba(145, 158, 171, 0.16)",
                    borderBottom: "1px solid rgba(145, 158, 171, 0.16)",
                  }}
                >
                  моё_заявление
                </TableCell>
                <TableCell
                  sx={{
                    borderTop: "1px solid rgba(145, 158, 171, 0.16)",
                    borderBottom: "1px solid rgba(145, 158, 171, 0.16)",
                  }}
                >
                  WORD
                </TableCell>
                <TableCell
                  sx={{
                    borderTop: "1px solid rgba(145, 158, 171, 0.16)",
                    borderBottom: "1px solid rgba(145, 158, 171, 0.16)",
                  }}
                >
                  3.4 MB
                </TableCell>
                <TableCell
                  sx={{
                    borderTop: "1px solid rgba(145, 158, 171, 0.16)",
                    borderBottom: "1px solid rgba(145, 158, 171, 0.16)",
                  }}
                >
                  <ListItemText primary="20 Jun 2023" secondary="10:21 AM" />
                </TableCell>
                <TableCell
                  key={1}
                  align="right"
                  sx={{
                    borderTopRightRadius: "16px",
                    borderBottomRightRadius: "16px",
                    borderRight: "1px solid rgba(145, 158, 171, 0.16)",
                    borderTop: "1px solid rgba(145, 158, 171, 0.16)",
                    borderBottom: "1px solid rgba(145, 158, 171, 0.16)",
                  }}
                >
                  <TableMoreMenu
                    open={openMenu}
                    onOpen={handleOpenMenu}
                    onClose={handleCloseMenu}
                    actions={
                      <>
                        <MenuItem
                          onClick={() => {
                            handleCloseMenu();
                            // handleOpenConfirmDialog()
                          }}
                          sx={{ color: "green" }}
                        >
                          <Iconify icon={"material-symbols:check"} />
                          Принять
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            // onEditRow();
                            handleCloseMenu();
                          }}
                          sx={{ color: "red" }}
                        >
                          <Iconify icon={"mdi:file-cancel-outline"} />
                          Отказать
                        </MenuItem>
                      </>
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  "&:hover": {
                    backgroundColor: "rgba(255,0,0,0.2)",
                    boxShadow: "rgba(145, 158, 171, 0.16) 0px 20px 40px -4px",
                  },
                  cursor: "pointer",
                  backgroundColor: "rgba(255,0,0,0.2)",
                  transition:
                    "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                }}
              >
                <TableCell
                  sx={{
                    borderTopLeftRadius: "16px",
                    borderBottomLeftRadius: "16px",
                    borderLeft: "1px solid rgba(145, 158, 171, 0.16)",
                    borderTop: "1px solid rgba(145, 158, 171, 0.16)",
                    borderBottom: "1px solid rgba(145, 158, 171, 0.16)",
                  }}
                >
                  <Stack flexDirection="row" alignItems="center" gap={2}>
                    <img height={36} width={36} src="/icons/ic_pdf.svg" />
                    <Typography>Медицинская справка по форме У-086</Typography>
                  </Stack>
                </TableCell>
                <TableCell
                  sx={{
                    borderTop: "1px solid rgba(145, 158, 171, 0.16)",
                    borderBottom: "1px solid rgba(145, 158, 171, 0.16)",
                  }}
                >
                  моё_заявление
                </TableCell>
                <TableCell
                  sx={{
                    borderTop: "1px solid rgba(145, 158, 171, 0.16)",
                    borderBottom: "1px solid rgba(145, 158, 171, 0.16)",
                  }}
                >
                  PDF
                </TableCell>
                <TableCell
                  sx={{
                    borderTop: "1px solid rgba(145, 158, 171, 0.16)",
                    borderBottom: "1px solid rgba(145, 158, 171, 0.16)",
                  }}
                >
                  3.4 MB
                </TableCell>
                <TableCell
                  sx={{
                    borderTop: "1px solid rgba(145, 158, 171, 0.16)",
                    borderBottom: "1px solid rgba(145, 158, 171, 0.16)",
                  }}
                >
                  <ListItemText primary="20 Jun 2023" secondary="10:21 AM" />
                </TableCell>
                <TableCell
                  key={2}
                  align="right"
                  sx={{
                    borderTopRightRadius: "16px",
                    borderBottomRightRadius: "16px",
                    borderRight: "1px solid rgba(145, 158, 171, 0.16)",
                    borderTop: "1px solid rgba(145, 158, 171, 0.16)",
                    borderBottom: "1px solid rgba(145, 158, 171, 0.16)",
                  }}
                >
                  <TableMoreMenu
                    open={openMenu}
                    onOpen={handleOpenMenu}
                    onClose={handleCloseMenu}
                    actions={
                      <>
                        <MenuItem
                          onClick={() => {
                            handleCloseMenu();
                          }}
                          sx={{ color: "green" }}
                        >
                          <Iconify icon={"material-symbols:check"} />
                          Принять
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            // onEditRow();
                            handleCloseMenu();
                          }}
                          sx={{ color: "red" }}
                        >
                          <Iconify icon={"mdi:file-cancel-outline"} />
                          Отказать
                        </MenuItem>
                      </>
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  "&:hover": {
                    backgroundColor: "rgba(255,0,0,0.2)",
                    boxShadow: "rgba(145, 158, 171, 0.16) 0px 20px 40px -4px",
                  },
                  cursor: "pointer",
                  backgroundColor: "rgba(255,0,0,0.2)",
                  transition:
                    "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                }}
              >
                <TableCell
                  sx={{
                    borderTopLeftRadius: "16px",
                    borderBottomLeftRadius: "16px",
                    borderLeft: "1px solid rgba(145, 158, 171, 0.16)",
                    borderTop: "1px solid rgba(145, 158, 171, 0.16)",
                    borderBottom: "1px solid rgba(145, 158, 171, 0.16)",
                  }}
                >
                  <Stack flexDirection="row" alignItems="center" gap={2}>
                    <img height={36} width={36} src="/icons/ic_pdf.svg" />
                    <Typography>
                      Олий маълумот тўғрисидаги хужжат 
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell
                  sx={{
                    borderTop: "1px solid rgba(145, 158, 171, 0.16)",
                    borderBottom: "1px solid rgba(145, 158, 171, 0.16)",
                  }}
                >
                  моё_заявление
                </TableCell>
                <TableCell
                  sx={{
                    borderTop: "1px solid rgba(145, 158, 171, 0.16)",
                    borderBottom: "1px solid rgba(145, 158, 171, 0.16)",
                  }}
                >
                  PDF
                </TableCell>
                <TableCell
                  sx={{
                    borderTop: "1px solid rgba(145, 158, 171, 0.16)",
                    borderBottom: "1px solid rgba(145, 158, 171, 0.16)",
                  }}
                >
                  3.4 MB
                </TableCell>
                <TableCell
                  sx={{
                    borderTop: "1px solid rgba(145, 158, 171, 0.16)",
                    borderBottom: "1px solid rgba(145, 158, 171, 0.16)",
                  }}
                >
                  <ListItemText primary="20 Jun 2023" secondary="10:21 AM" />
                </TableCell>
                <TableCell
                  key={3}
                  align="right"
                  sx={{
                    borderTopRightRadius: "16px",
                    borderBottomRightRadius: "16px",
                    borderRight: "1px solid rgba(145, 158, 171, 0.16)",
                    borderTop: "1px solid rgba(145, 158, 171, 0.16)",
                    borderBottom: "1px solid rgba(145, 158, 171, 0.16)",
                  }}
                >
                  <TableMoreMenu
                    open={openMenu}
                    onOpen={handleOpenMenu}
                    onClose={handleCloseMenu}
                    actions={
                      <>
                        <MenuItem
                          onClick={() => {
                            handleCloseMenu();
                          }}
                          sx={{ color: "green" }}
                        >
                          <Iconify icon={"material-symbols:check"} />
                          Принять
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            // onEditRow();
                            handleCloseMenu();
                          }}
                          sx={{ color: "red" }}
                        >
                          <Iconify icon={"mdi:file-cancel-outline"} />
                          Отказать
                        </MenuItem>
                      </>
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  "&:hover": {
                    backgroundColor: "rgba(255,0,0,0.2)",
                    boxShadow: "rgba(145, 158, 171, 0.16) 0px 20px 40px -4px",
                  },
                  cursor: "pointer",
                  backgroundColor: "rgba(255,0,0,0.2)",
                  transition:
                    "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                }}
              >
                <TableCell
                  sx={{
                    borderTopLeftRadius: "16px",
                    borderBottomLeftRadius: "16px",
                    borderLeft: "1px solid rgba(145, 158, 171, 0.16)",
                    borderTop: "1px solid rgba(145, 158, 171, 0.16)",
                    borderBottom: "1px solid rgba(145, 158, 171, 0.16)",
                  }}
                >
                  <Stack flexDirection="row" alignItems="center" gap={2}>
                    <img height={36} width={36} src="/icons/ic_pdf.svg" />
                    <Typography>Паспорт</Typography>
                  </Stack>
                </TableCell>
                <TableCell
                  sx={{
                    borderTop: "1px solid rgba(145, 158, 171, 0.16)",
                    borderBottom: "1px solid rgba(145, 158, 171, 0.16)",
                  }}
                >
                  моё_заявление
                </TableCell>
                <TableCell
                  sx={{
                    borderTop: "1px solid rgba(145, 158, 171, 0.16)",
                    borderBottom: "1px solid rgba(145, 158, 171, 0.16)",
                  }}
                >
                  PDF
                </TableCell>
                <TableCell
                  sx={{
                    borderTop: "1px solid rgba(145, 158, 171, 0.16)",
                    borderBottom: "1px solid rgba(145, 158, 171, 0.16)",
                  }}
                >
                  3.4 MB
                </TableCell>
                <TableCell
                  sx={{
                    borderTop: "1px solid rgba(145, 158, 171, 0.16)",
                    borderBottom: "1px solid rgba(145, 158, 171, 0.16)",
                  }}
                >
                  <ListItemText primary="20 Jun 2023" secondary="10:21 AM" />
                </TableCell>
                <TableCell
                  key={4}
                  align="right"
                  sx={{
                    borderTopRightRadius: "16px",
                    borderBottomRightRadius: "16px",
                    borderRight: "1px solid rgba(145, 158, 171, 0.16)",
                    borderTop: "1px solid rgba(145, 158, 171, 0.16)",
                    borderBottom: "1px solid rgba(145, 158, 171, 0.16)",
                  }}
                >
                  <TableMoreMenu
                    open={openMenu}
                    onOpen={handleOpenMenu}
                    onClose={handleCloseMenu}
                    actions={
                      <>
                        <MenuItem
                          onClick={() => {
                            handleCloseMenu();
                          }}
                          sx={{ color: "green" }}
                        >
                          <Iconify icon={"material-symbols:check"} />
                          Принять
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            // onEditRow();
                            handleCloseMenu();
                          }}
                          sx={{ color: "red" }}
                        >
                          <Iconify icon={"mdi:file-cancel-outline"} />
                          Отказать
                        </MenuItem>
                      </>
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  "&:hover": {
                    boxShadow: "rgba(145, 158, 171, 0.16) 0px 20px 40px -4px",
                  },
                  cursor: "pointer",

                  transition:
                    "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                }}
              >
                <TableCell
                  sx={{
                    borderTopLeftRadius: "16px",
                    borderBottomLeftRadius: "16px",
                    borderLeft: "1px solid rgba(145, 158, 171, 0.16)",
                    borderTop: "1px solid rgba(145, 158, 171, 0.16)",
                    borderBottom: "1px solid rgba(145, 158, 171, 0.16)",
                  }}
                >
                  <Stack flexDirection="row" alignItems="center" gap={2}>
                    <img height={36} width={36} src="/icons/ic_word.svg" />
                    <Typography>Льготы</Typography>
                  </Stack>
                </TableCell>
                <TableCell
                  sx={{
                    borderTop: "1px solid rgba(145, 158, 171, 0.16)",
                    borderBottom: "1px solid rgba(145, 158, 171, 0.16)",
                  }}
                >
                  моё_заявление
                </TableCell>
                <TableCell
                  sx={{
                    borderTop: "1px solid rgba(145, 158, 171, 0.16)",
                    borderBottom: "1px solid rgba(145, 158, 171, 0.16)",
                  }}
                >
                  WORD
                </TableCell>
                <TableCell
                  sx={{
                    borderTop: "1px solid rgba(145, 158, 171, 0.16)",
                    borderBottom: "1px solid rgba(145, 158, 171, 0.16)",
                  }}
                >
                  3.4 MB
                </TableCell>
                <TableCell
                  sx={{
                    borderTop: "1px solid rgba(145, 158, 171, 0.16)",
                    borderBottom: "1px solid rgba(145, 158, 171, 0.16)",
                  }}
                >
                  <ListItemText primary="20 Jun 2023" secondary="10:21 AM" />
                </TableCell>
                <TableCell
                  key={5}
                  align="right"
                  sx={{
                    borderTopRightRadius: "16px",
                    borderBottomRightRadius: "16px",
                    borderRight: "1px solid rgba(145, 158, 171, 0.16)",
                    borderTop: "1px solid rgba(145, 158, 171, 0.16)",
                    borderBottom: "1px solid rgba(145, 158, 171, 0.16)",
                  }}
                >
                  <TableMoreMenu
                    open={openMenu}
                    onOpen={handleOpenMenu}
                    onClose={handleCloseMenu}
                    actions={
                      <>
                        <MenuItem
                          key={2}
                          onClick={() => {
                            handleCloseMenu();
                            handleOpenConfirmDialog();
                          }}
                          sx={{ color: "green" }}
                        >
                          <Iconify icon={"material-symbols:check"} />
                          Принять
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            // onEditRow();
                            handleCloseMenu();
                          }}
                          sx={{ color: "red" }}
                        >
                          <Iconify icon={"mdi:file-cancel-outline"} />
                          Отказать
                        </MenuItem>
                      </>
                    }
                  />
                </TableCell>
              </TableRow> */}
            </TableBody>
          </Table>
        </Card>
      </Stack>

      <Stack
        alignSelf=""
        gap={1}
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        position="fixed"
        bottom={0}
        sx={{ background: "#fff" }}
        padding={1}
        width={800}
      >
        {/* <Button variant="contained" fullWidth>
          Cохранить
        </Button> */}
        <Button variant="contained" fullWidth onClick={onClose}>
          Закрыть
        </Button>
      </Stack>
    </Drawer>
  );
}
