import PropTypes from "prop-types";
import {
  Stack,
  InputAdornment,
  TextField,
  MenuItem,
  ListSubheader,
  Box,
  Select,
} from "@mui/material";
// components
import Iconify from "../../../../components/Iconify";

// ----------------------------------------------------------------------

// UserTableToolbar.propTypes = {
//   filterName: PropTypes.string,
//   filterRole: PropTypes.string,
//   onFilterName: PropTypes.func,
//   onFilterRole: PropTypes.func,
//   optionsRole: PropTypes.arrayOf(PropTypes.object),
// };

export default function UserTableToolbar({
  filterName,
  filterRole,
  onFilterName,
  onFilterRole,
  optionsRole,
}) {
  return (
    <Stack
      spacing={2}
      direction={{ xs: "column", sm: "row" }}
      sx={{ py: 2.5, px: 3 }}
    >
      <Select
        fullWidth
        native
        label="Направления"
        value={filterRole}
        onChange={onFilterRole}
        SelectProps={{
          MenuProps: {
            sx: { "& .MuiPaper-root": { maxHeight: 260 } },
          },
        }}
        sx={{
          maxWidth: { sm: 240 },
          textTransform: "capitalize",
        }}
      >
        <option
          value="all"
          sx={{
            mx: 1,
            my: 0.5,
            borderRadius: 0.75,
            typography: "body2",
            textTransform: "capitalize",
          }}
        >
          Все
        </option>
        {optionsRole.map((option) => (
          <optgroup key={option.id} label={option.name}>
            {option?.children?.map((child) => (
              <option key={child.id} value={child.id}>
                {child.name}
              </option>
            ))}
            {}
          </optgroup>
        ))}
      </Select>

      {/* <TextField
        fullWidth
        value={filterName}
        onChange={(event) => onFilterName(event.target.value)}
        placeholder="Search user..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          ),
        }}
      /> */}
    </Stack>
  );
}
