import PropTypes from "prop-types";
import { useState } from "react";
// @mui
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Checkbox,
  TableRow,
  TableCell,
  Typography,
  MenuItem,
} from "@mui/material";
// components
import Label from "../../../../components/Label";
import Iconify from "../../../../components/Iconify";
import { TableMoreMenu } from "../../../../components/table";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedStatement } from "src/redux/slices/statement";
import SendMessageDialog from "src/components/SendMessageDialog";
import Lightbox from "react-image-lightbox";

// ----------------------------------------------------------------------

UserTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
};

export default function UserTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
}) {
  const theme = useTheme();

  const { user, phone, status, name, photo, major, isVerified } = row;
  const [openSendMessageDialog, setOpenSendMessageDialog] = useState(false);
  const [openMenu, setOpenMenuActions] = useState(null);
  const [isShowImg, setIsShowImg] = useState(false);

  const dispatch = useDispatch();

  const handleOpenMenu = (event) => {
    event.stopPropagation();
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const openDrawer = (e) => {
    if (!openMenu) {
      onSelectStatement();
      onSelectRow();
    }
  };

  const onSelectStatement = () => {
    dispatch(setSelectedStatement(row));
  };

  const handleOpenSendMessageDialog = () => {
    setOpenSendMessageDialog(true);
  };

  const handleCloseSendMessageDialog = () => {
    setOpenSendMessageDialog(false);
  };

  return (
    <>
      <TableRow hover onClick={openDrawer}>
        <TableCell sx={{ display: "flex", alignItems: "center" }}>
          <Avatar
            onClick={(e) => {
              if (photo) {
                e.stopPropagation();
                setIsShowImg(true);
              }
            }}
            alt={`${user?.middle_name} ${user?.name} ${user?.last_name}`}
            src={photo}
            sx={{ mr: 2 }}
          />
          <Typography variant="subtitle2" noWrap>
            {`${user?.middle_name} ${user?.name} ${user?.last_name}`}
          </Typography>
        </TableCell>

        <TableCell align="left">{user?.phone}</TableCell>

        <TableCell align="left" sx={{ textTransform: "capitalize" }}>
          {user?.email}
        </TableCell>

        <TableCell align="center">{major?.name || "Танланмаган"}</TableCell>

        {/* <TableCell align="center">
          <Iconify
            icon={
              isVerified ? "eva:checkmark-circle-fill" : "eva:clock-outline"
            }
            sx={{
              width: 20,
              height: 20,
              color: "success.main",
              ...(!isVerified && { color: "warning.main" }),
            }}
          />
        </TableCell> */}
        {/* 
      <TableCell align="left">
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={(status === 'banned' && 'error') || 'success'}
          sx={{ textTransform: 'capitalize' }}
        >
          {status}
        </Label>
      </TableCell> */}

        <TableCell align="right">
          <TableMoreMenu
            open={openMenu}
            onOpen={handleOpenMenu}
            onClose={handleCloseMenu}
            actions={
              <>
                <MenuItem
                  onClick={() => {
                    handleCloseMenu();
                    handleOpenSendMessageDialog();
                  }}
                  sx={{ color: "orange" }}
                >
                  <Iconify icon={"ep:message"} />
                  Янги хабар
                </MenuItem>
                {/* <MenuItem
                  onClick={(e) => {
                    openDrawer();
                    handleCloseMenu();
                  }}
                >
                  <Iconify icon={"mdi:show-outline"} />
                  Просмотр
                </MenuItem> */}
              </>
            }
          />
        </TableCell>
      </TableRow>
      <SendMessageDialog
        // userId={infoData?.user?.id}
        open={openSendMessageDialog}
        onClose={handleCloseSendMessageDialog}
      />
      {isShowImg && (
        <Lightbox
          mainSrc={photo}
          // nextSrc={images[(photoIndex + 1) % images.length]}
          // prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsShowImg(false)}
          // onMovePrevRequest={() =>
          //   this.setState({
          //     photoIndex: (photoIndex + images.length - 1) % images.length,
          //   })
          // }
          // onMoveNextRequest={() =>
          //   this.setState({
          //     photoIndex: (photoIndex + 1) % images.length,
          //   })
          // }
        />
      )}
    </>
  );
}
