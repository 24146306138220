import { createSlice } from "@reduxjs/toolkit";
import sum from "lodash/sum";
import uniqBy from "lodash/uniqBy";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  error: null,
  isLoading: false,
  data: null,
  major: {
    isLoading: false,
    data: [],
  },
  filterForTable: {
    data: [],
    isLoading: false,
  },
  institutes: {
    isLoading: false,
    data: [],
  },
  studyTypes: {
    isLoading: false,
    data: [],
  },
  statistics: {
    isLoading: false,
    data: [],
  },
};

const slice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    // SHOW LOADING REGIONS
    startLoadingUserData(state) {
      state.isLoading = true;
    },

    // SHOW LOADING Statistics
    startLoadingStatisticData(state) {
      state.statistics.isLoading = true;
    },

    // SHOW LOADING AREAS
    startLoadingMajor(state) {
      state.major.isLoading = true;
    },
    // SHOW LOADING AREAS
    startLoadingFilterMajor(state) {
      state.filterForTable.isLoading = true;
    },

    // SHOW LOADING INSTITUTES
    startLoadingInstitutes(state) {
      state.institutes.isLoading = true;
    },

    // SHOW LOADING STUDY TYPES
    startLoadingStudyTypes(state) {
      state.studyTypes.isLoading = true;
    },

    // ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.major.isLoading = false;
      state.error = action.payload;
    },

    // USER DATA
    userDataSuccess(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    },

    // STATISTIC DATA
    statisticDataSuccess(state, action) {
      state.statistics.isLoading = false;
      state.statistics.data = action.payload;
    },

    // USER DATA
    setDataSuccess(state, action) {
      state.data[action.payload.docType] = action.payload;
    },

    // MAJORS
    majorsSuccess(state, action) {
      state.major.isLoading = false;
      state.major.data = action.payload;
    },

    // MAJORS
    filterMajorsSuccess(state, action) {
      state.filterForTable.isLoading = false;
      state.filterForTable.data = action.payload;
    },

    // INSTITUTES
    institutesSuccess(state, action) {
      state.institutes.isLoading = false;
      state.institutes.data = action.payload;
    },

    // STUDY TYPES
    studyTypesSuccess(state, action) {
      state.studyTypes.isLoading = false;
      state.studyTypes.data = action.payload;
    },

    clearDatas(state, action) {
      state.data = null;
    },
  },
});

// Reducer
export default slice.reducer;

export const { clearDatas } = slice.actions;

// ----------------------------------------------------------------------

export function getUserData() {
  return async () => {
    dispatch(slice.actions.startLoadingUserData());
    try {
      const response = await axios.get("/qabul/student");

      dispatch(slice.actions.userDataSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError("Ошибка загрузки данных"));
    }
  };
}

// ----------------------------------------------------------------------

export function saveUserData(data, cb, errorCb) {
  return async () => {
    dispatch(slice.actions.startLoadingUserData());
    try {
      const response = await axios.patch("/qabul/student", data);

      dispatch(slice.actions.userDataSuccess(response.data[0]));
      cb();
    } catch (error) {
      errorCb(error);
      dispatch(slice.actions.hasError("Ошибка загрузки данных"));
    }
  };
}

// ----------------------------------------------------------------------

export function getMajors(academyId) {
  return async () => {
    dispatch(slice.actions.startLoadingMajor());
    try {
      const response = await axios.get(`/qabul/major?academy_id=${academyId}`);

      dispatch(slice.actions.majorsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError("Ошибка загрузки данных"));
    }
  };
}
// ----------------------------------------------------------------------

export function getFilterMajors() {
  return async () => {
    dispatch(slice.actions.startLoadingFilterMajor());
    try {
      const response = await axios.get(`/qabul/manager/category_filter`);

      dispatch(slice.actions.filterMajorsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError("Ошибка загрузки данных"));
    }
  };
}

// ----------------------------------------------------------------------

export function getInstitutes(regionId) {
  return async () => {
    dispatch(slice.actions.startLoadingInstitutes());
    try {
      const response = await axios.get(`/qabul/academy?region_id=${regionId}`);

      dispatch(slice.actions.institutesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError("Ошибка загрузки данных"));
    }
  };
}

// ----------------------------------------------------------------------

export function getStudyTypes(majorId) {
  return async () => {
    dispatch(slice.actions.startLoadingStudyTypes());
    try {
      const response = await axios.get(`/qabul/type-study?major_id=${majorId}`);

      dispatch(slice.actions.studyTypesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError("Ошибка загрузки данных"));
    }
  };
}

// ----------------------------------------------------------------------

export function setDocumentData(docType, data) {
  return async () => {
    dispatch(slice.actions.setDataSuccess({ docType, ...data }));
  };
}

// ----------------------------------------------------------------------
export function getStatistics() {
  return async () => {
    dispatch(slice.actions.startLoadingStatisticData());
    try {
      const response = await axios.get(`/qabul/manager/student_daily`);

      dispatch(slice.actions.statisticDataSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError("Ошибка загрузки данных"));
    }
  };
}
