import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { confirmDocument } from "src/redux/slices/statement";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";

export default function ConfirmDocumentDialog({
  open,
  onClose,
  fileName,
  docType,
  userId,
}) {
  const dispatch = useDispatch();
  const { confirmLoading } = useSelector((state) => state.statement);
  const { enqueueSnackbar } = useSnackbar();

  const onConfirm = () => {
    dispatch(
      confirmDocument(userId, docType, () => {
        onClose();
        enqueueSnackbar("Документ подтверждён!");
      })
    );
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Вы хотите подтвердить корректность документа?"}
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Файл: {fileName}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="info">
            Закрыть
          </Button>
          <LoadingButton onClick={onConfirm} autoFocus loading={confirmLoading}>
            Подтвердить
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
